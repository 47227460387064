import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import LastSeen from "../components/LastSeen";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";

const DashboardAdminContracts = () => {
  const [loading, setLoading] = useState(true);
  const { contracts, getAllContractsList } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const fetchContracts = async () => {
    await getAllContractsList();
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    } else {
      fetchContracts();
    }
    return () => setLoading(true);
  }, []);

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Contracts</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="">
                      <div className="table-responsive ">
                        <table className="table table-striped mt-3">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Job Title
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Client
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Talent
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Created
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              [1, 2, 3, 4, 5].map((index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                    <Skeleton width={250} height={26} />
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={150} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={150} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                </tr>
                              ))
                            ) : contracts.length > 0 ? (
                              contracts.map((contract, index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th
                                    scope="row"
                                    className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                  >
                                    <div className="">
                                      <Link
                                        to={`/job-details/${contract.jobId}`}
                                        state={{ id: contract?.jobId }}
                                        className="font-size-4 mb-0 font-weight-semibold text-black-2"
                                      >
                                        {contract?.jobTitle || "N/A"}
                                      </Link>
                                    </div>
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                      {contract?.client}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                      {contract?.talent}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                      <LastSeen
                                        date={
                                          new Date(contract?.created.toDate())
                                        }
                                      />
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                      {contract?.status}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Link
                                      to={`/contract-details/${contract.id}`}
                                      state={{ id: contract?.id }}
                                      className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                    >
                                      Details
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="border border-color-2">
                                <td
                                  scope="row"
                                  colSpan={6}
                                  className="pl-6 border-0 py-7 pr-0"
                                >
                                  <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                    No contracts found at the moment
                                  </h4>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdminContracts;
